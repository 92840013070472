import * as React from "react"
import Layout from "../components/layout"
import '../components/index.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import { registerBeta } from '../utils/api'
import { Link } from "gatsby"

const pageStyles = {
  color: "#232129",
  padding: "96px 12px",
  fontFamily: "Roboto, sans-serif, serif",
  maxWidth: "45rem",
  margin: "auto"
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: "calc(90% - 64px)",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Allowed = ({ info }) => {
  return <div></div>
}

const NotAllowed = ({ info }) => {
  return <div></div>
}

const IndexPage = ({ location }) => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [sendingRequest, setSendingRequest] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setSuccess(null)
  }

  return (
    <Layout location={location}>
      <div style={pageStyles}>
        <h1 style={{ textAlign: "left" }}>License for our Snapshot product</h1>
        <Allowed info={"Use the dataset received into your own personal and commercial products."} />
        <div>For pictures and videos, the terms and conditions of Pexel apply.</div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
